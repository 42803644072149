import React from "react";
import Image from "../../../components/UI/Image";
import google from "../../../assets/images/LandingPage/SocialMediaIcons/google_logo.png";
const GoogleBtn = ({ onPrivacyPolicyCheck }) => {
  return (
    <>
      <div className="googleLogo" onClick={() => onPrivacyPolicyCheck(false)}>
        <Image src={google} width={20} height={20} />
      </div>
    </>
  );
};

export default GoogleBtn;
