import axios from "axios";
import React, { useEffect, useState } from "react";
import Config from "../../../Config/config";
import { fetchSysparValue } from "../../../Utilities/Fetch/FetchSysparValue";
import { loginSuccess } from "../../../services/Actions/userSlice";
import { useDispatch } from "react-redux";
import { RequestMethod } from "../../../Utilities/Reusable/Method/RequestMethod";
import { getCompanyProfile } from "../../../services/Actions/companyProfileSlice";
import { getProfile } from "../../../services/Actions/profileSlice";
import { useNavigate } from "react-router-dom";
import { setfeatures } from "../../../services/Actions/featuresAction";
import CustomModal from "../../../components/UI/Modal";

const GoogleLogin = ({ onError, onSuccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientId, setClientId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loginSSOData, setLoginSSOData] = useState({});
  useEffect(() => {
    fetchSysparValue("GOOGLE_CLIENT_ID", setClientId);
  }, [clientId]);

  const loadGoogleSSO = async () => {
    if (window.google && window.google.accounts && window.google.accounts.id) {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: onGoogleLogin,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          theme: "outlined",
          size: "large",
          shape: "pill",
          type: "icon",
        }
      );
    } else {
      console.error("Google API is not available.");
    }
  };

  const onGoogleLogin = async (response) => {
    // console.log("Encoded JWT ID token: " + response.credential);

    const token = response.credential;

    try {
      // const googleResponse = await axios.post(
      //   `${Config.nxtApi}/api/user/login/google`,
      //   {
      //     token,
      //   }
      // );

      const googleResponse = await RequestMethod(
        "post",
        "nxtApi",
        "/api/user/login/google",
        { token },
        null,
        null
      );
      console.log(googleResponse);
      // onSuccess(googleResponse.data.message);
      // dispatch(loginSuccess(googleResponse.data));
      if (
        googleResponse.data.userType === "applicant" ||
        googleResponse.data.userType === "talent"
      ) {
        fetchTalentProfile(googleResponse.data.token, googleResponse.data);
      } else if (googleResponse.data.userType === "client") {
        fetchClientProfile(googleResponse.data.token, googleResponse.data);
      }
    } catch (error) {
      console.log(error);
      onError(error?.data?.message || "");
      // console.log(error.data.message);
    }
  };

  const fetchClientProfile = async (token, data) => {
    try {
      const result = await RequestMethod(
        "get",
        "nxtApi",
        "/api/client/myprofile",
        null,
        token
      );

      if (result.status === 200) {
        setShowModal(true);
        setLoginSSOData({
          profile: result.data.data[0],
          features: result.data.subscription,
          data: data,
        });

        onSuccess(data.data.message);
      }
      // dispatch(loginSuccess(data));
      // navigate("/client/company-profile");
      // console.log("Data:", result.data.data[0]);
      // dispatch(getProfile(result.data.data[0]));
      // dispatch(loginSuccess(data));
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  // Get Talent Profile
  const fetchTalentProfile = async (token, data) => {
    console.log(data);
    try {
      const response = await RequestMethod(
        "get",
        "nxtApi",
        "/api/talent/myprofile",
        null,
        token
      );

      if (response.status === 200) {
        setShowModal(true);
        setLoginSSOData({
          profile: response.data.data[0],
          // features: response.data.subscription,
          data: data,
        });
        // dispatch(getProfile(response.data.data[0]));
        onSuccess(data.data.message);
      }
      // dispatch(loginSuccess(data));
      // navigate("/talent/profile");
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  useEffect(() => {
    loadGoogleSSO();
  }, [clientId]);

  const handleSuccess = () => {
    if (!loginSSOData.features) {
      dispatch(loginSuccess(loginSSOData.data));
      dispatch(getProfile(loginSSOData.profile));
    } else {
      dispatch(getCompanyProfile(loginSSOData.profile));
      dispatch(setfeatures(loginSSOData.features));
      dispatch(loginSuccess(loginSSOData.data));
    }

    setShowModal(false);
  };

  return (
    <div>
      <div id="buttonDiv"> </div>
      <CustomModal
        show={showModal}
        modalType={"Success"}
        modalMessage="You are successfully logged in"
        buttonName="Ok"
        onClick={() => {
          handleSuccess();
        }}
      />
    </div>
  );
};

export default GoogleLogin;
