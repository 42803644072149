import { useEffect, useState, useRef } from "react";
import { useAuthToken, useRoleKey } from "../../Utilities/Auth/Token";
import axios from "axios";
import Config from "../../Config/config";
import { useSelector } from "react-redux";
import { handleAxiosError } from "../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
import useErrorHandler from "../error/useErrorHandler";

export const useRole = () => {
  const companyName = useSelector(
    (state) => state.reducer.currentUser?.currentUser?.companyName
  );
  const token = useAuthToken();
  const code = useRoleKey();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };
  const [errorRole, setErrorRole] = useState(null);
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [delUserData, setDelUserData] = useState(null);
  //   const hasFetchedRole = useRef(false); // Flag to check if the role has been fetched

  const getRoleKey = async (roleToken = null) => {
    // if (hasFetchedRole.current) return; // Prevent multiple requests
    const header = {
      Authorization: `Bearer ${roleToken || token}`,
      ...(code && { rolekey: code }),
    };

    setIsLoading(true);
    try {
      const res = await axios.get(`${Config.nxtApi}/api/client/role`, {
        headers: header,
      });

      setRole(res.data.result);
      console.log("error");
      //   hasFetchedRole.current = true;
    } catch (error) {
      console.log(error);
      // setErrorRole(error);
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [addUserStatus, setAddUserStatus] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const addUserAPI = async (userData) => {
    setErrorRole(null);
    setIsLoading(true);
    try {
      const res = await axios.post(`${Config.nxtApi}/api/role`, userData, {
        headers,
      });
      console.log(res);
      setAddUserStatus(res.status);
    } catch (error) {
      console.log(error);
      setErrorRole(error);
    } finally {
      setIsLoading(false);
    }
  };

  const delUserAPI = async (roleId, prevUpdatedAt) => {
    setErrorRole(null);
    setIsLoading(true);
    try {
      const res = await axios.delete(`${Config.nxtApi}/api/role`, {
        data: {
          roleId, // This is now part of the payload
          prevUpdatedAt,
        },
        headers, // Your custom headers
      });

      setDelUserData(res);
    } catch (error) {
      if (error.response.status >= 403) {
        handleAxiosError(error);
      } else {
        setErrorRole(error);
      }
      // handleError(error, "client", "deleteUser");
    } finally {
      setIsLoading(false);
    }
  };

  const [editUserRes, setEditUserStatus] = useState(null);
  const editUserAPI = async ({ editData, data }) => {
    setErrorRole(null);
    console.log(editData, data);
    setIsLoading(true);

    const payload = {
      patchOptions: {
        clientId: editData.clientId,
      },
      setOptions: {
        roleType: data.roleType,
      },
      prevUpdatedAt: editData.updatedAt,
    };

    try {
      const res = await axios.patch(`${Config.nxtApi}/api/role`, payload, {
        headers,
      });

      console.log(res);
      setEditUserStatus(res.status);
      // setUserStatus(res.status);
      // setDelUserData(res);
    } catch (error) {
      console.log(error);
      if (error.response.status >= 403) {
        handleAxiosError(error);
      } else {
        setErrorRole(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const [allClientUserList, setAllClientUserList] = useState(null);
  const getAllClientUsers = async () => {
    setErrorRole(null);
    // if (hasFetchedRole.current) return; // Prevent multiple requests

    setIsLoading(true);
    try {
      const res = await axios.get(`${Config.nxtApi}/api/role`, {
        headers,
      });

      setAllClientUserList(res.data.result);
      //   hasFetchedRole.current = true;
    } catch (error) {
      console.log(error);
      if (error.response.status >= 403) {
        handleAxiosError(error);
      } else {
        setErrorRole(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    delUserAPI,
    addUserAPI,
    getRoleKey,
    role,
    isLoading,
    delUserData,
    addUserStatus,
    getAllClientUsers,
    allClientUserList,
    editUserAPI,
    userStatus,
    errorRole,
    editUserRes,
  };
};
