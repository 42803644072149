import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  ProtectRoutesAdmin,
  ProtectRoutesClient,
  ProtectRoutesTalent,
} from "./routes/ProtectedRoutes";

import ValidatedProfileRoutes from "./routes/ValidatedProfileRoutes";

import EnterEmail from "./pages/Outside/ForgotPassword/Form/EnterEmail";
import SetupPassword from "./pages/Outside/ForgotPassword/Form/SetupPassword";
import Registration from "./pages/Outside/Registration/User/MultistepForm/Registration";
import FAQ from "./pages/Outside/FAQ/FAQ";
import ScrollToTop from "./components/ScrollToTop";
import LandingPage from "./pages/LandingPage/LandingPage";
import About from "./pages/About/About";
import Contact from "./pages/Outside/Contact/Contact";
import PrivacyPolicy from "./pages/Outside/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./pages/Outside/TermsAndCondition/TermsAndCondition";

import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import ProfileTalent from "./pages/Talent/ProfileTalent";

// ATS
import AttendanceTrackingManageJob from "./pages/Admin/ATS/ManageJob/AttendanceTrackingManageJob";
import AttendanceTrackingCreateJob from "./pages/Admin/ATS/ManageJob/AttendanceTrackingCreateJob";
import ATS from "./pages/Admin/ATS/MainPage";
import Login from "./pages/Outside/Login/User/Login";
import AdminLogin from "./pages/Outside/Login/Admin/Login";
import CompanyProfile from "./pages/Client/CompanyProfile/CompanyProfile";

//Client
import JobPosting from "./pages/JobPosting";
import ManageJob from "./pages/ManageJob";
import ApplicantList from "./pages/Client/ApplicantList";
import ViewResume from "./pages/Client/ViewResume";
import TalentList from "./pages/Client/TalentList";
import Subscription from "./pages/Client/Subscription";
import SubscriptionSelect from "./pages/Client/SubscriptionSelect";
import SubscriptionConfiguration from "./pages/Client/SubscriptionConfiguration";
import SubscriptionCheckout from "./pages/Client/SubscriptionCheckout";
import ManageUser from "./pages/Client/ManageUser";

// talent
import MyResumeTalent from "./pages/Talent/MyResumeTalent";
import JobListPageTalent from "./pages/Talent/JobListPageTalent";
import SelectTalent from "./pages/Client/SelectTalent";
import JobListDetailsPageTalent from "./pages/Talent/JobListDetailsPageTalent";
import AppliedJobPageTalent from "./pages/Talent/AppliedJobPageTalent";
import ViewJobDetails from "./pages/Talent/ViewJobDetails";

import Joblist from "./pages/Outside/PubJobList/JobList/Joblist";
import JobDetails from "./pages/Outside/PubJobList/JobDetails/JobDetails";
import AddAdmin from "./pages/SuperAdmin/AddAdmin";

import SecretReferralPage from "./pages/SecretReferralPage";
import ACQ from "./pages/Subs/AQC";
import ASTICOM from "./pages/Subs/ASTICOM";
import ABSI from "./pages/Subs/ABSI";
import FINSI from "./pages/Subs/FINSI";
import HCX from "./pages/Subs/HCX";
import ComingSoon from "./pages/ComingSoon";
import ComingSoonInside from "./pages/ComingSoonInside";
import NotFound from "./pages/Outside/NotFound/NotFound";
import CustomNotFound from "./pages/Outside/NotFound/CustomNotFound";
import InvalidCode from "./pages/Outside/NotFound/InvalidCode";
import AdminSubscription from "./pages/Admin/Subscription/Subscription";
import AdminManageJobs from "./pages/Admin/ManageJobs/ManageJobs";
import IdleTimerLogout from "./Utilities/Reusable/Logout/IdleTimerLogout";
import HiredList from "./pages/Admin/ATS/HiredList/HiredList";
import PoolingList from "./pages/Admin/ATS/PoolingList/PoolingList";
import AtsTalentList from "./pages/Admin/ATS/TalentList/AtsTalentList";
import SubscriptionHistory from "./pages/Client/SubscriptionHistory";
import SubscriptionLastPage from "./pages/Client/SubscriptionLastPage";
import UserManagement from "./pages/Admin/ATS/UserManagement/UserManagement";
import AtsDashboard from "./pages/Admin/ATS/Dashboard/AtsDashboard";

import { ValidatedAtsRoutes } from "./routes/ValidatedAtsRoutes";

import GigsterLandingPage from "./pages/Gigster/GigsterLandingPage";
import GigsterActivate from "./pages/Gigster/GigsterActivatePage";
import UnderMaintenance from "./pages/UnderMaintenance";

import { CustomModalError } from "./Utilities/Reusable/ErrorHandler/CustomModalError";
import { InactivityError } from "./Utilities/Reusable/ErrorHandler/InactivityError";
import { useSelector } from "react-redux";
import SubscriptionAts from "./pages/Admin/ATS/Subscription/Subscription";
import ChatAssistantPageTalent from "./pages/Talent/ChatAssistantPageTalent";
import LoginSuperAdmin from "./pages/SuperAdmin/Login";
import SuperAdminClientList from "./pages/SuperAdmin/SuperAdminClientList/SuperAdminClientList";
import TrackingSystem from "./pages/TrackingSystem/TrackingSystem";
import DataDeletion from "./pages/Outside/DataDeletion/DataDeletion";
import CookiePolicy from "./pages/Outside/CookiePolicy/CookiePolicy";
import ChoosePlan from "./pages/Outside/ChoosePlan/ChoosePlan";
import LoginTalent from "./pages/Outside/Login/User/LoginTalent";
import LoginClient from "./pages/Outside/Login/User/LoginClient";
import JobSeeker from "./pages/WhatsNext/JobSeeker/index.jsx";
import WhatsNxt from "./pages/WhatsNext/WhatsNext/index.jsx";
import Employer from "./pages/WhatsNext/Employer/index.jsx";
import RegistrationClient from "./pages/Outside/Registration/User/MultistepForm/RegistrationClient.js";
import RegistrationTalent from "./pages/Outside/Registration/User/MultistepForm/RegistrationTalent.js";
import ChatBot from "./pages/Admin/ATS/ChatBot/ChatBot.js";
import AddOn from "./pages/Admin/ATS/Subscription/AddOn.js";
import AddOnConfig from "./pages/Admin/ATS/Subscription/AddOn/AddOnStep/AddOnConfig.js";
import AddOnCheckout from "./pages/Admin/ATS/Subscription/AddOn/AddOnStep/AddOnCheckout.js";
import AddOnSubscribe from "./pages/Admin/ATS/Subscription/AddOn/AddOnStep/AddOnSubscribe.js";
import Pricing from "./pages/Outside/Pricing/Pricing.js";
import ProductSubscribe from "./pages/Outside/Product/ProductSubscribe.js";
import ProductHome from "./pages/Outside/Product/ProductHome.js";
import AttendanceTrackingAiQuestionare from "./pages/Admin/ATS/ManageJob/AttendanceTrackingAiQuestionare.js";
import JobListDetailsAiQuestionarePagTalent from "./pages/Talent/JobListDetailsAiQuestionarePagTalent.js";
import SubscriptionAddOns from "./pages/Admin/ATS/Subscription/SubscriptionAddOns.js";
import SubscriptionConfig from "./pages/Admin/ATS/Subscription/SubscriptionConfig.js";
import SubsCheckOut from "./pages/Admin/ATS/Subscription/SubscriptionCheckout.js";
import { errorMessages } from "./Utilities/Reusable/Message/errorMessage.js";

function App() {
  const isAIMLOn = useSelector((state) => state.reducer.aimlInfo?.isOn);
  const isOnClient = useSelector((state) => state.reducer.aimlInfo?.isOnClient);
  const isAIMLFeature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.withAI
  );

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const isAuth = useSelector((state) => state.reducer.user.isAuthenticated);
  const userInfo = useSelector((state) => state.reducer.user?.currentUser);

  const [errorLocal, setErrorLocal] = useState(localStorage.getItem("error"));
  const [errCode, setErrCode] = useState(localStorage.getItem("errCode"));
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedError = localStorage.getItem("error");
      const updatedErrCode = localStorage.getItem("errCode");
      setErrorLocal(updatedError);
      setErrCode(updatedErrCode);

      // Check if the error code is relevant
      if (["401", "403"].includes(updatedError)) {
        if (updatedErrCode) {
          const message = errorMessages[updatedErrCode] || "An error occurred.";
          setErrorMessage(message);
        } else {
          setErrorMessage("An error occurred. Please try again.");
        }
        setIsErrorModalOpen(true);
      } else {
        setIsErrorModalOpen(false);
      }
    };

    handleStorageChange();
    const intervalId = setInterval(handleStorageChange, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [errorLocal]);

  const handleCloseModal = () => {
    setIsErrorModalOpen(false);
    localStorage.removeItem("error"); // Clear error code
    localStorage.removeItem("errCode"); // Clear errCode as well
  };

  const handleIdleTimeout = () => {
    setIsErrorModalOpen(true);
  };

  useEffect(() => {
    let interval;

    const resetInterval = () => {
      clearInterval(interval);
      interval = setInterval(() => {
        if (isAuth) {
          InactivityError(handleIdleTimeout);
        }
      }, 900000);
    };

    const handleActivity = () => {
      resetInterval();
    };

    if (isAuth) {
      resetInterval();

      document.addEventListener("mousemove", handleActivity);
      document.addEventListener("keydown", handleActivity);
      document.addEventListener("scroll", handleActivity);
      document.addEventListener("click", handleActivity);

      return () => {
        document.removeEventListener("mousemove", handleActivity);
        document.removeEventListener("keydown", handleActivity);
        document.removeEventListener("scroll", handleActivity);
        document.removeEventListener("click", handleActivity);
        clearInterval(interval);
      };
    }
  }, [isAuth]);
  console.log(isErrorModalOpen);
  return (
    <div className="App">
      <Router>
        <CustomModalError
          isOpen={isErrorModalOpen}
          errCode={errCode}
          errorNumber={errorLocal}
          errorMessage={errorMessage}
          onClose={handleCloseModal}
        />

        <Routes>
          {/* Client Routes*/}
          <Route element={<ProtectRoutesClient />}>
            <Route
              path="/client/company-profile"
              element={<CompanyProfile />}
            />

            <Route element={<ValidatedProfileRoutes />}>
              <Route path="/client/job-posting" element={<JobPosting />} />

              <Route path="/client/manage-job" element={<ManageJob />} />
              <Route
                path="/client/applicant-list"
                element={<ApplicantList />}
              />
              <Route path="/client/view-resume" element={<ViewResume />} />
              <Route path="/client/talent-list" element={<TalentList />} />
              {isOnClient && (
                <Route path="/client/subscription" element={<Subscription />} />
              )}

              {/* <Route
                path="/client/subscription-history"
                element={<SubscriptionHistory />}
              /> */}
              <Route
                path="/client/subscription-select"
                element={<SubscriptionSelect />}
              />
              <Route
                path="/client/subscription-config"
                element={<SubscriptionConfiguration />}
              />
              <Route
                path="/client/subscription-checkout"
                element={<SubscriptionCheckout />}
              />
              <Route
                path="/client/subscription-subscribed"
                element={<SubscriptionLastPage />}
              />
              <Route
                path="/client/view-job-details/:id"
                element={<ViewJobDetails />}
              />
              <Route path="/client/manage-user" element={<ManageUser />} />
            </Route>
          </Route>
          {/* Talent Routes */}
          <Route element={<ProtectRoutesTalent />}>
            <Route path="/talent/profile" element={<ProfileTalent />} />
            <Route element={<ValidatedProfileRoutes />}>
              <Route path="/talent/my-resume" element={<MyResumeTalent />} />
              <Route path="/talent/job-list" element={<JobListPageTalent />} />
              {isAIMLOn && (
                <>
                  {" "}
                  <Route
                    path="/talent/chat-assistant"
                    element={<ChatAssistantPageTalent />}
                  />
                </>
              )}

              <Route path="/talent/select-talent" element={<SelectTalent />} />
              <Route
                path="/talent/job-list-details/:id"
                element={<JobListDetailsPageTalent />}
              />
              <Route
                path="/talent/job-list-details/ai-questionare/:id"
                element={<JobListDetailsAiQuestionarePagTalent />}
              />
              <Route
                path="/talent/applied-job"
                element={<AppliedJobPageTalent />}
              />
            </Route>
          </Route>
          {/* <Route element={<ProtectRoutesAdmin />}> */}
          <Route path="/admin/dashboard" element={<Dashboard />} />
          {/* </Route> */}
          <Route element={<ValidatedAtsRoutes />}>
            <Route path="/admin/ats" element={<ATS />} />
            <Route
              path="/admin/ats/manage-job"
              element={<AttendanceTrackingManageJob />}
            />
            <Route
              path="/admin/ats/managing-job"
              element={<AttendanceTrackingCreateJob />}
            />
            {isAIMLFeature && (
              <Route
                path="/admin/ats/managing-job/ai-questionare"
                element={<AttendanceTrackingAiQuestionare />}
              />
            )}
            <Route path="/admin/ats/talent-list" element={<AtsTalentList />} />{" "}
            <Route path="/admin/ats/pooling-list" element={<PoolingList />} />
            <Route path="/admin/ats/hired-list" element={<HiredList />} />
            <Route path="/admin/ats/user" element={<UserManagement />} />
            <Route
              path="/admin/ats/subscription"
              element={<SubscriptionAts />}
            />
            <Route path="/admin/ats/subscription/add-ons" element={<AddOn />} />
            <Route
              path="/admin/ats/subscription/subscription-addons"
              element={<SubscriptionAddOns />}
            />
            <Route
              path="/admin/ats/subscription/subscription-config"
              element={<SubscriptionConfig />}
            />
            <Route
              path="/admin/ats/subscription/subscription-checkout"
              element={<SubsCheckOut />}
            />
            <Route
              path="/admin/ats/subscription/config"
              element={<AddOnConfig />}
            />
            <Route
              path="/admin/ats/subscription/checkout"
              element={<AddOnCheckout />}
            />
            <Route
              path="/admin/ats/subscription/subscribed"
              element={<AddOnSubscribe />}
            />
            <Route path="/admin/ats/dashboard" element={<AtsDashboard />} />
            <Route path="/admin/subscription" element={<AdminSubscription />} />
            <Route path="/admin/manage-jobs" element={<AdminManageJobs />} />
            {isOnClient && isAIMLFeature && (
              <Route path="/admin/ats/chatbot" element={<ChatBot />} />
            )}
          </Route>
          {/*  Public routes*/}
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/pricing" element={<ProductSubscribe />} />
          <Route path="/product" element={<ProductHome />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/data-deletion" element={<DataDeletion />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/talent/login" element={<LoginTalent />} />
          <Route path="/client/login" element={<LoginClient />} />
          <Route path="/forgot-password" element={<EnterEmail />} />
          <Route path="/setup-password" element={<SetupPassword />} />
          {/* <Route path="/registration" element={<Registration />} /> */}
          <Route path="/client/registration" element={<RegistrationClient />} />
          <Route path="/talent/registration" element={<RegistrationTalent />} />
          <Route path="/choose-plan" element={<ChoosePlan />} />
          <Route path="/nxt/jobseeker" element={<JobSeeker />} />
          <Route path="/nxt" element={<WhatsNxt />} />
          <Route path="/nxt/employer" element={<Employer />} />
          <Route
            path="/applicant-tracking-system"
            element={<TrackingSystem />}
          />
          {/* <Route path="/admin/login" element={<AdminLogin />} /> */}
          {/* <Route path="/admin/login" element={<AdminLogin />} /> */}
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/jobs" element={<Joblist />} />
          <Route path="/jd/:id" element={<JobDetails />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/admin/superadmin/" element={<AdminLogin />} />
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "} */}
          {/* <Route path="/admin/superadmin/" element={<Login />} />
           <Route path="/admin/superadmin/dashboard" element={<Dashboard />} /> */}
          <Route path="/admin/superadmin/add-admin" element={<AddAdmin />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/invalid-code" element={<InvalidCode />} />
          <Route path="/nxt/secretcode" element={<SecretReferralPage />} />{" "}
          <Route path="/lp/acquiro" element={<ACQ />} />
          <Route path="/lp/asticom" element={<ASTICOM />} />
          <Route path="/lp/absi" element={<ABSI />} />
          <Route path="/lp/finsi" element={<FINSI />} />
          <Route path="/lp/hcx" element={<HCX />} />
          <Route path="/about" element={<About />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
          <Route path="/comingsoonin" element={<ComingSoonInside />} />{" "}
          <Route path="/job-not-found" element={<CustomNotFound />} />
          {/* under maintenance */}
          <Route path="/under-maintenance" element={<UnderMaintenance />} />
          <Route path="*" element={<NotFound />} />
          {/* gigster */}
          <Route path="/gigster" element={<GigsterLandingPage />} />
          <Route path="/gigster-activate" element={<GigsterActivate />} />
          {/* Admin */}
          <Route path="/admin/client-list" element={<SuperAdminClientList />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
