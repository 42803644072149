import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import ManageJobFormPagination from "./ManageJobFormPagination";
import ManageJobFormTable from "./ManageJobFormTable";
import axios from "axios";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken, useRoleKey } from "../../../../../Utilities/Auth/Token";
import CustomModal from "../../../../../components/UI/Modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoutAndClearProfile } from "../../../../../services/Actions/logoutAction";
import { handleAxiosError } from "../../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
import { useMessageHandler } from "../../../../../hook/error/messageHandler";
const ManageJobForm = () => {
  const {
    showModal,
    modalMessage,
    messageType,
    showLogoutButton,
    handleResponse,
    handleError,
    // handleLogout,
    closeModal,
  } = useMessageHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [allError, setAllError] = useState("");
  const token = useAuthToken();
  const devBase = localDevBase();
  const [jobs, setJobs] = useState([]);
  const [jobData, setJobData] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [jobNextDataList, setJobNextDataList] = useState("");
  const [dataNextFilter, setDataNextFilter] = useState("");
  const [cursor, setCursor] = useState();
  const code = useRoleKey();
  const [pageError, setPageError] = useState(null);
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${devBase}/api/ats/manage-job`, {
        headers,
      });
      const result = response?.data?.result;
      const allJobs = result?.data;

      const filteredJobs = allJobs.filter(
        (job) =>
          job.jobTitle.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          job.jobUUID.toLowerCase().includes(searchKeyword.toLowerCase())
      );

      console.log(response);
      setJobData(response);

      setJobs(filteredJobs);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response?.status >= 403) {
        handleAxiosError(error);
      }
      setPageError(error);
      // handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDataNextFilter = (data) => {
    setDataNextFilter(data);
  };

  const reloadData = async () => {
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [searchKeyword]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columnsPerPage = 10;
  const startIndex = (currentPage - 1) * columnsPerPage;
  const endIndex = Math.min(startIndex + columnsPerPage, jobs.length);
  const visibleJobs = jobs.slice(startIndex, endIndex);

  const jobCount = jobs.length;

  const handleLogout = () => {
    setModalShow(false);
    setAllError("");
    dispatch(LogoutAndClearProfile());
    localStorage.clear();
    sessionStorage.clear();
    console.log("Logout ...");
    navigate("/");
  };

  const handleCursorData = (newData) => {
    setCursor(newData);
    console.log(newData);
  };

  const handleJobListData = (value) => {
    console.log(value);
    setJobNextDataList(value);
  };

  const handleGetError = (value) => {
    setPageError(value);
  };
  useEffect(() => {
    console.log("My page Errorr", pageError);
    if (pageError) {
      handleError(pageError, "client", "getJobs");
    }
  }, [pageError]);

  return (
    <>
      <h3 className="manage-job__main_title border-bottom pb-3 mb-4">
        Manage Job
      </h3>
      <Form>
        <div style={{ minHeight: "690px" }}>
          <ManageJobFormTable
            jobNextData={jobNextDataList}
            jobs={visibleJobs}
            columnsPerPage={columnsPerPage}
            setSearchKeyword={setSearchKeyword}
            setJobs={setJobs}
            reloadData={reloadData}
            loadTable={loading}
            dataNextFilter={handleDataNextFilter}
            cursor={handleCursorData}
          />
        </div>
        <ManageJobFormPagination
          dataFromSelection={dataNextFilter}
          jobNextData={handleJobListData}
          jobData={jobData}
          jobs={jobs}
          columnsPerPage={columnsPerPage}
          count={jobCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          pageJobs={setJobs}
          cursorData={cursor}
          manageJobPaginationError={handleGetError}
        />
      </Form>
      <CustomModal
        show={modalShow}
        modalMessage={
          allError === "Error"
            ? "You have been automatically logged out"
            : "Something Went Wrong"
        }
        modalType={"Error"}
        onHide={() => setModalShow(false)}
        buttonName="Confirm"
        onClick={handleLogout}
      />
      {showModal && (
        <CustomModal
          hasOption={false}
          show={showModal}
          buttonName="OK"
          onHide={closeModal}
          modalMessage={modalMessage}
          modalType={messageType}
          onClick={closeModal}
          showLogoutButton={showLogoutButton}
          handleLogout={handleLogout}
        />
      )}
    </>
  );
};

export default ManageJobForm;
