import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import CustomContactNumber from "../../../../../components/Form/CustomContactNumber";
import useCreateJobSchema, {
  CreateJobSchema,
} from "../../../../../Utilities/Reusable/Validation/Schema/ATS/AtsSchema";
import CustomInput from "../../../../../components/Form/CustomInput";
import CustomSelect from "../../../../../components/Form/CustomSelect";

import CustomRichText from "../../../../../components/Form/CustomRichText";
import HiringManager from "./Form/HiringManager";
import JobInformation from "./Form/JobInformation";
import JobRequirement from "./Form/JobRequirement";
import PostingDate from "./Form/PostingDate";
import { useLocation, useNavigate } from "react-router";
import { GetData } from "../../../../../Utilities/Fetch/GetData";
import axios from "axios";
import { extractValues } from "../../../../../Utilities/Reusable/Function/CreatableExtract";
import { stripHtmlTags } from "../../../../../Utilities/Reusable/Function/StripHtmlTags";
import { convertToISODate } from "../../../../../Utilities/Reusable/Function/ConvertToIso";
import { getModifiedFields } from "../../../../../Utilities/Reusable/Function/GetModifiedFields";
import moment from "moment";
import Config from "../../../../../Config/config";
import "../../../../../assets/scss/CreateJob/createjob.scss";
import StatusID from "./Form/StatusID";
import { localDevBase } from "../../../../../services/API/localDevBase";
import CustomModal from "../../../../../components/UI/Modal";
import { useAuthToken, useRoleKey } from "../../../../../Utilities/Auth/Token";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../../layouts/loader/Loader";
import { handleAxiosError } from "../../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";

const CreateJobForm = () => {
  const token = useAuthToken();
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const devBase = localDevBase();
  const navigate = useNavigate();
  const [responseJobId, setResponseJobId] = useState(null);
  const isAIMLFeature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.withAI
  );
  const code = useRoleKey();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };
  const handleGoBack = () => {
    window.history.back(); // Navigates back in the browser's history
  };

  // If it is Edit
  const location = useLocation();
  const forEditData = location.state?.editVal;

  console.log(forEditData);
  const isViewJob = location.state?.editVal?.isViewJob;
  const prevUpdatedAt = location.state?.editVal?.prevUpdatedAt;
  console.log(
    isViewJob,
    prevUpdatedAt,
    "PREEEEEEEEEEEV =============================="
  );
  const numberOfJobs = location.state?.numberOfJobs;
  console.log(numberOfJobs);
  const companyName = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile.companyName
  );

  const methods = useForm({
    resolver: zodResolver(useCreateJobSchema(forEditData)),
  });

  const submitData = async (data, buttonType) => {
    console.log("----------------", buttonType, data, forEditData);
    const jobStatus = forEditData
      ? forEditData?.jobPostStatus
      : buttonType === "draft" || buttonType === "saveAsDraftAI"
      ? "draft"
      : "open";
    console.log(buttonType);
    const {
      name,
      email,
      contactNumber,
      skillSet,
      benefits,
      dialect,
      startDate,
      endDate,
      startTime,
      endTime,
      jobOverview,
      jobDescription,
      qualification,
      isActive,
      ...newData
    } = data;

    // Get values
    const skillSetValues = extractValues(skillSet);
    const benefitsSetValues = extractValues(benefits);
    const dialectValues = extractValues(dialect);

    // Convert Plain
    const jobOverviewPlain = stripHtmlTags(jobOverview);
    const jobDescriptionPlain = stripHtmlTags(jobDescription);
    const qualificationPlain = stripHtmlTags(qualification);

    // Convert ISO
    const postingDateStartDateISO = convertToISODate(startDate);
    const postingDateEndDateISO = convertToISODate(endDate);

    console.log(jobStatus);
    const jobPostStatus = isAIMLFeature
      ? jobStatus
      : buttonType === "publish"
      ? "open"
      : "draft";

    const formattedStartTime = moment(startTime, "HH:mm").format("hh:mm A");
    const formattedEndTime = moment(endTime, "HH:mm").format("hh:mm A");

    const payload = {
      ...newData,
      benefits: benefitsSetValues,
      skillSet: skillSetValues,
      dialect: dialectValues,
      postingDate: { startDate, endDate },
      officeHours: `${formattedStartTime} - ${formattedEndTime}`,
      hiringManager: {
        name,
        email,
        contactNumber,
      },
      companyName,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      jobPostStatus,
      jobOverview,
      jobDescription,
      qualification,
      jobOverviewPlain,
      jobDescriptionPlain,
      qualificationPlain,
      postingDateStartDateISO,
      postingDateEndDateISO,
      isActive,
    };

    let modalMode = "";
    if (forEditData) {
      const editPayLoad = {
        patchOptions: {
          jobId: forEditData.jobId,
          updatedAt: forEditData.updatedAt,
        },
        setOptions: payload,
        props: [""],
      };

      console.log("================== ", editPayLoad);
      if (
        buttonType === "saveAsDraft" ||
        buttonType === "publish" ||
        buttonType === "saveAsDraftAI"
      ) {
        // console.log(
        //   `${
        //     buttonType === "saveAsDraft" ? "Save as Draft" : "Publish"
        //   } clicked`
        // );

        editJob(editPayLoad);
        modalMode =
          buttonType === "saveAsDraft"
            ? "Draft"
            : buttonType === "publish"
            ? "Add"
            : "";

        // setModalMode(modalMode);
        // setIsLoadingPublish(true);
      }
    } else {
      const payload = {
        ...newData,
        benefits: benefitsSetValues,
        skillSet: skillSetValues,
        dialect: dialectValues,
        postingDate: { startDate, endDate },
        officeHours: `${formattedStartTime} - ${formattedEndTime}`,
        hiringManager: {
          name,
          email,
          contactNumber,
        },
        companyName,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        jobPostStatus,
        jobOverview,
        jobDescription,
        qualification,
        jobOverviewPlain,
        jobDescriptionPlain,
        qualificationPlain,
        postingDateStartDateISO,
        postingDateEndDateISO,
      };

      if (
        buttonType === "saveAsDraft" ||
        buttonType === "publish" ||
        buttonType === "saveAsDraftAI"
      ) {
        // console.log(
        //   `${
        //     buttonType === "saveAsDraft" ? "Save as Draft" : "Publish"
        //   } clicked`
        // );
        createJob(payload);
        modalMode =
          buttonType === "saveAsDraft"
            ? "Draft"
            : buttonType === "publish"
            ? "Add"
            : "";
        // setModalMode(modalMode);
        // setIsLoadingDraft(true);
      }
    }
  };

  const [loadPublisJobBtn, setLoadPublishJobBtn] = useState(false);
  const [loadSaveAsDraftJobBtn, setLoadSaveAsDraftJobBtn] = useState(false);
  const createJob = async (payload) => {
    if (isAIMLFeature) {
      console.log(payload);
      // JobPost Open or Draft
      if (payload.jobPostStatus === "open") {
        // setLoadPublishJobBtn(true);
        setModalMode("Add");
      } else {
        setLoadSaveAsDraftJobBtn(true);
        // setModalMode("Draft");
      }

      if (numberOfJobs <= 20 || payload.jobPostStatus === "draft") {
        console.log("first");
        // setIsLoadingDraft(false);
        // setIsLoadingPublish(false);

        try {
          const response = await axios.put(
            `${devBase}/api/ats/manage-job`,
            payload,
            { headers }
            // withCredentials: true,
          );
          // balikan mo sya yeihihihih pag my id na
          console.log(response.data.jobId, response);
          setResponseJobId(response.data.jobId);
          // setModalShow(true);
          setIsLoadingDraft(false);

          //
          setLoadPublishJobBtn(false);
          setLoadSaveAsDraftJobBtn(false);

          if (response && payload.jobPostStatus === "draft" && isAIMLFeature) {
            navigate("/admin/ats/managing-job/ai-questionare", {
              state: {
                jobId: response.data.jobId,
                payload: payload,
                editJob: forEditData ? true : false,
              },
            });
          }
        } catch (error) {
          //
          setLoadPublishJobBtn(false);
          setLoadSaveAsDraftJobBtn(false);
          //
          setIsLoadingDraft(false);
          handleAxiosError(error);
          console.log(error);
        }
        // return;
      } else {
        setModalMode("exceededJobPosts");
        console.log("s");
        setModalShow(true);
        setIsLoadingDraft(false);
        setIsLoadingPublish(false);
        // return;
      }

      setIsLoadingDraft(false);
      setIsLoadingPublish(false);
    } else {
      if (payload.jobPostStatus === "open") {
        setLoadPublishJobBtn(true);
        setModalMode("Add");
      } else {
        setLoadSaveAsDraftJobBtn(true);
        setModalMode("Draft");
      }

      if (numberOfJobs <= 20 || payload.jobPostStatus === "draft") {
        console.log("first");
        // setIsLoadingDraft(false);
        // setIsLoadingPublish(false);

        try {
          const response = await axios.put(
            `${devBase}/api/ats/manage-job`,
            payload,
            { headers }
            // withCredentials: true,
          );
          // balikan mo sya yeihihihih pag my id na
          console.log(response.data.jobId, response);
          setResponseJobId(response.data.jobId);
          setModalShow(true);
          setIsLoadingDraft(false);

          //
          setLoadPublishJobBtn(false);
          setLoadSaveAsDraftJobBtn(false);

          // if (response && payload.jobPostStatus === "draft" && isAIMLFeature) {
          //   navigate("/admin/ats/managing-job/ai-questionare", {
          //     state: {
          //       jobId: response.data.jobId,
          //       payload: payload,
          //       editJob: forEditData ? true : false,
          //     },
          //   });
          // }
        } catch (error) {
          //
          setLoadPublishJobBtn(false);
          setLoadSaveAsDraftJobBtn(false);
          //
          setIsLoadingDraft(false);
          handleAxiosError(error);
          console.log(error);
        }
        // return;
      } else {
        setModalMode("exceededJobPosts");
        console.log("s");
        setModalShow(true);
        setIsLoadingDraft(false);
        setIsLoadingPublish(false);
        // return;
      }

      setIsLoadingDraft(false);
      setIsLoadingPublish(false);
    }
  };

  const editJob = async (payload) => {
    console.log(payload);
    if (!isAIMLFeature) {
      if (payload.setOptions.jobPostStatus === "open") {
        console.log("first");
        setModalMode("Add");
        setIsLoadingPublish(true);

        //
        setLoadPublishJobBtn(true);
      } else {
        console.log("second");
        // setModalMode("Draft");
        setIsLoadingDraft(true);
      }

      if (numberOfJobs <= 20 || payload.jobPostStatus !== "open") {
        const { isActive, ...newData } = payload;
        const atsPay = {
          ...newData,
        };

        const atsPayload = [
          {
            patchOptions: {
              jobId: atsPay.patchOptions.jobId,
              updatedAt: atsPay.patchOptions.updatedAt,
            },
            setOptions: {
              isActive: atsPay.setOptions.isActive,
            },
          },
        ];

        // const atsStatus = await axios.patch(
        //   `${devBase}/api/ats/manage-job/atsJob`,
        //   atsPayload,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );

        // console.log(atsStatus);
        delete atsPay.setOptions.isActive;

        try {
          const response = await axios.patch(
            `${devBase}/api/ats/manage-job/`,
            atsPay,
            {
              params: { prevUpdatedAt: atsPay.patchOptions.updatedAt },
              headers,
            }
            // withCredentials: true,
          );
          console.log(response);
          setResponseJobId(response.data.result.data.jobId);
          // setModalShow(true);
          setIsLoadingPublish(false);
          setIsLoadingDraft(false);

          //
          setLoadPublishJobBtn(false);
        } catch (error) {
          //
          setLoadPublishJobBtn(false);
          //
          setIsLoadingPublish(false);
          handleAxiosError(error);
          console.log(error);
        }
      } else {
        setModalMode("exceededJobPosts");
        console.log("s");
        // setModalShow(true);
        setIsLoadingDraft(false);
        setIsLoadingPublish(false);
      }
    } else {
      // console.log(payload);
      navigate("/admin/ats/managing-job/ai-questionare", {
        state: {
          jobId: payload.patchOptions.jobId,
          payload: payload,
          allPayload: forEditData,
          editJob: forEditData ? true : false,
        },
      });
    }
  };

  const handleClickCancel = () => {
    navigate("/admin/ats/manage-job");
  };

  let forViewJob = isViewJob
    ? "View Job"
    : forEditData
    ? "Edit Job"
    : "Create Job";

  // View Option Modal
  const viewDeleteOptionModal = () => {
    setModalShow(true);
    setModalMode("deleteOption");
  };

  // Delete Job

  const [loadCloseBtn, setLoadCloseBtn] = useState(false);
  const handleDelete = async () => {
    setLoadCloseBtn(true);
    const deleteJobPayload = {
      patchOptions: {
        jobId: forEditData.jobId,
        updatedAt: forEditData.updatedAt,
      },
      setOptions: {
        jobPostStatus: "closed",
      },

      props: [forEditData.createdAt],
    };

    try {
      const response = await axios.delete(`${devBase}/api/ats/manage-job/`, {
        data: deleteJobPayload,
        headers,
        params: {
          prevUpdatedAt: prevUpdatedAt,
        },
        // withCredentials: true,
      });
      console.log(response);
      setLoadCloseBtn(false);
      setModalMode("successDeleted");
    } catch (error) {
      setLoadCloseBtn(false);
      if (error.response.status == 400) {
        setModalShow(true);
        setModalMode("cantDelete");
      }

      handleAxiosError(error);
      console.log(error);
    }
  };

  const handleInvalidStatusAts = (isValid) => {
    console.log("ATS Status:", isValid);
    setModalMode(isValid);
    setModalShow(true);
  };

  const [aiData, setAiData] = useState(null);
  console.log(aiData);

  useEffect(() => {
    console.log(aiData);
    if (aiData?.content === null || aiData?.content === "nullData") {
      setModalMode("noAIJobFound");
      setModalShow(true);
    } else if (aiData?.content === "empty") {
      setModalMode("emptyAI");
      setModalShow(true);
    }
  }, [aiData]);

  console.log("************", forEditData);
  return (
    <>
      <h3
        className="create-job__main_title border-bottom pb-3 mb-4"
        id="topCreateForm"
      >
        {forViewJob}
      </h3>

      <div className="create-job__form pt-2 px-5 pb-5">
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(submitData)}>
            {forEditData && (
              <StatusID
                editData={forEditData}
                isViewJob={isViewJob}
                checkJobStatus={handleInvalidStatusAts}
              />
            )}
            <HiringManager editData={forEditData} isViewJob={isViewJob} />
            <JobInformation
              editData={forEditData}
              isViewJob={isViewJob}
              getGeneratedData={setAiData}
            />
            <JobRequirement
              editData={forEditData}
              isViewJob={isViewJob}
              aiData={aiData}
            />
            <PostingDate editData={forEditData} isViewJob={isViewJob} />
            <div className="d-flex">
              <div className="ms-auto">
                <Button
                  variant="outline-secondary"
                  className="me-2"
                  onClick={handleClickCancel}
                  disabled={
                    isLoadingDraft ||
                    isLoadingPublish ||
                    loadPublisJobBtn ||
                    loadSaveAsDraftJobBtn ||
                    loadCloseBtn
                  }
                >
                  <i class="fa-regular fa-circle-xmark"></i> Cancel
                </Button>

                {!isAIMLFeature && (
                  <>
                    {(forEditData?.jobPostStatus === "open" ||
                      forEditData?.jobPostStatus === "expired") && (
                      <Button
                        variant="outline-secondary"
                        className="me-2 create-job__btn_red"
                        onClick={() => viewDeleteOptionModal()}
                        disabled={
                          isLoadingDraft ||
                          isLoadingPublish ||
                          loadSaveAsDraftJobBtn ||
                          loadCloseBtn
                        }
                      >
                        <i class="fa-solid fa-file"></i> Close
                      </Button>
                    )}
                  </>
                )}

                {!isAIMLFeature && forEditData?.jobPostStatus === "draft" && (
                  <Button
                    variant="outline-secondary"
                    className="me-2 create-job__btn_yellow"
                    onClick={() =>
                      methods.handleSubmit((data) =>
                        submitData(data, "saveAsDraft")
                      )()
                    }
                    disabled={
                      isLoadingDraft ||
                      isLoadingPublish ||
                      loadPublisJobBtn ||
                      loadSaveAsDraftJobBtn
                    }
                  >
                    <i class="fa-solid fa-file"></i>{" "}
                    {loadSaveAsDraftJobBtn ? <Loader /> : "Save as Draft"}
                  </Button>
                )}

                {/* if isAIMLFeature show button for save as draft else publish*/}
                {isAIMLFeature && forEditData?.jobPostStatus !== "closed" ? (
                  <Button
                    variant="outline-secondary create-job__btn_blue"
                    type="submit"
                    onClick={() =>
                      methods.handleSubmit((data) =>
                        submitData(
                          data,
                          isAIMLFeature === false ? "publish" : "saveAsDraftAI"
                        )
                      )()
                    }
                    disabled={
                      isLoadingDraft ||
                      loadPublisJobBtn ||
                      loadSaveAsDraftJobBtn
                    }
                  >
                    <i class="bi bi-layer-forward"></i> {}
                    {loadPublisJobBtn || isAIMLFeature === false
                      ? "Publish"
                      : "Next"}
                  </Button>
                ) : (
                  <>
                    {!isViewJob && (
                      <Button
                        variant="outline-secondary create-job__btn_blue"
                        type="submit"
                        onClick={() =>
                          methods.handleSubmit((data) =>
                            submitData(
                              data,
                              isAIMLFeature === false
                                ? "publish"
                                : "saveAsDraft"
                            )
                          )()
                        }
                        disabled={
                          isLoadingDraft ||
                          loadPublisJobBtn ||
                          loadSaveAsDraftJobBtn
                        }
                      >
                        <i class="bi bi-layer-forward"></i> {}
                        {loadPublisJobBtn || isAIMLFeature === false
                          ? "Publish"
                          : "Next"}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* {isViewJob ? (
              <div className="d-flex">
                <div className="ms-auto">
                  <Button
                    variant="outline-secondary"
                    className="me-2"
                    onClick={handleGoBack}
                  >
                    Go Back
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <div className="ms-auto">
                    <Button
                      variant="outline-secondary"
                      className="me-2"
                      onClick={handleClickCancel}
                      disabled={
                        isLoadingDraft ||
                        isLoadingPublish ||
                        loadPublisJobBtn ||
                        loadSaveAsDraftJobBtn ||
                        loadCloseBtn
                      }
                    >
                      <i class="fa-regular fa-circle-xmark"></i> Cancel
                    </Button>

                    {(forEditData?.jobPostStatus === "open" ||
                      forEditData?.jobPostStatus === "expired") && (
                      <Button
                        variant="outline-secondary"
                        className="me-2 create-job__btn_red"
                        onClick={() => viewDeleteOptionModal()}
                        disabled={
                          isLoadingDraft ||
                          isLoadingPublish ||
                          loadSaveAsDraftJobBtn ||
                          loadCloseBtn
                        }
                      >
                        <i class="fa-solid fa-file"></i> Close
                      </Button>
                    )}

                    {((isAIMLFeature === false && !forEditData) ||
                      forEditData?.jobPostStatus === "draft") && (
                      <Button
                        variant="outline-secondary"
                        className="me-2 create-job__btn_yellow"
                        onClick={() =>
                          methods.handleSubmit((data) =>
                            submitData(data, "saveAsDraft")
                          )()
                        }
                        disabled={
                          isLoadingDraft ||
                          isLoadingPublish ||
                          loadPublisJobBtn ||
                          loadSaveAsDraftJobBtn
                        }
                      >
                        <i class="fa-solid fa-file"></i>{" "}
                        {loadSaveAsDraftJobBtn ? <Loader /> : "Save as Draft"}
                      </Button>
                    )}
                    {isViewJob ? (
                      <></>
                    ) : (
                      <>
                        <Button
                          variant="outline-secondary create-job__btn_blue"
                          type="submit"
                          onClick={() =>
                            methods.handleSubmit((data) =>
                              submitData(
                                data,
                                isAIMLFeature === false
                                  ? "publish"
                                  : "saveAsDraftAI"
                              )
                            )()
                          }
                          disabled={
                            isLoadingDraft ||
                            loadPublisJobBtn ||
                            loadSaveAsDraftJobBtn
                          }
                        >
                          <i class="bi bi-layer-forward"></i> {}
                          {loadPublisJobBtn || isAIMLFeature === false
                            ? "Publish"
                            : "Next"}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </>
            )} */}
          </Form>
        </FormProvider>
        <CustomModal
          show={modalShow}
          hasOption={true}
          onHide={() => setModalShow(false)}
          modalMessage={
            modalMode === "Add" || modalMode === "addATSForm"
              ? "Job Published"
              : modalMode === "Draft"
              ? "Your job posting draft has been saved. Press 'OK' to continue"
              : modalMode === "deleteOption"
              ? "Are you sure to close this job posting"
              : modalMode === "successDeleted"
              ? "Successfully closed"
              : modalMode === "cantDelete" || modalMode === "cantDeleteForm"
              ? "Applicants processing is ongoing. Job can’t be removed in ATS"
              : modalMode === "notOpen"
              ? "Job status must be open to change ATS status"
              : modalMode === "removeATSForm"
              ? "Successfully removed from ATS"
              : modalMode === "exceededJobPosts"
              ? "Exceeded Job Posts"
              : modalMode === "noAIJobFound"
              ? "No job information found"
              : modalMode === "emptyAI"
              ? "Job title should not be empty"
              : "Something Went Wrong"
          }
          buttonName="OK"
          modalType={
            modalMode === "Add" ||
            modalMode === "addATSForm" ||
            modalMode === "Draft Saved" ||
            modalMode === "successDeleted" ||
            modalMode === "Draft" ||
            modalMode === "removeATSForm"
              ? "Success"
              : modalMode === "deleteOption" || modalMode === "notOpen"
              ? "Warning"
              : modalMode === "cantDelete" ||
                modalMode === "cantDeleteForm" ||
                modalMode === "exceededJobPosts" ||
                modalMode === "noAIJobFound" ||
                modalMode === "emptyAI"
              ? "Oops!"
              : "Oops!"
          }
          loading={loadCloseBtn}
          onClick={() => {
            modalMode === "addATSForm" ||
            modalMode === "successDeleted" ||
            modalMode === "cantDelete" ||
            modalMode === "exceededJobPosts"
              ? navigate("/admin/ats/manage-job")
              : modalMode === "deleteOption"
              ? handleDelete()
              : (modalMode === "Add" && isAIMLFeature == false) ||
                (modalMode === "Draft" && isAIMLFeature == false)
              ? navigate("/admin/ats/manage-job", {
                  state: { jobId: responseJobId },
                })
              : (modalMode === "Add" && isAIMLFeature == true) ||
                (modalMode === "Draft" && isAIMLFeature == true)
              ? navigate("/admin/ats/managing-job/ai-questionare", {
                  state: { jobId: responseJobId, editVal: "edit" },
                })
              : modalMode === "notOpen" ||
                modalMode === "removeATSForm" ||
                modalMode === "cantDeleteForm" ||
                modalMode === "noAIJobFound" ||
                modalMode === "emptyAI"
              ? setModalShow(false)
              : setModalShow(false);
          }}
        />
      </div>
    </>
  );
};

export default CreateJobForm;
