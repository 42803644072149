import React, { useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Container,
} from "reactstrap";
import "../assets/scss/Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useAuthToken, useRoleKey } from "../Utilities/Auth/Token";
import { getCurrentUser } from "../services/Actions/currentUser";
import { updatefeatures } from "../services/Actions/featuresAction";
import { useRole } from "../hook/role/useRole";
import { useMessageHandler } from "../hook/error/messageHandler";
import CustomModal from "../components/UI/Modal";

function Header() {
  const { code } = useRoleKey();
  const token = useAuthToken();
  const { role, isLoading, getRoleKey, errorRole } = useRole();
  console.log(role);

  const {
    showModal,
    modalMessage,
    messageType,
    handleResponse,
    showLogoutButton,
    handleLogout,
    handleError,
    closeModal,
  } = useMessageHandler();
  console.log(showLogoutButton);
  const clientId = useSelector(
    (state) => state.reducer.currentUser?.currentUser?.clientId
  );
  const companyName = useSelector(
    (state) => state.reducer.currentUser?.currentUser?.companyName
  );

  const { company, features } = useSelector(
    (state) => state.reducer.currentLoginUser?.currentLoginUser
  );

  console.log("CURRENT", company, features);
  console.log(companyName);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(clientId);
  const [currentUser, setCurrentUser] = useState(companyName);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const toggleOffcanvas = () => setIsOpen(!isOpen);

  const handleCompanyProfile = () => navigate("/client/company-profile");
  const handleTalentList = () =>
    navigate(role?.withATS ? "/admin/ats/talent-list" : "/client/talent-list");
  const handleAddpostjobClick = () =>
    navigate(role?.withATS ? "/admin/ats/managing-job" : "/client/job-posting");
  const handleManageJob = () => navigate("/client/manage-job");
  const handleClickLogo = () => navigate("/");

  const isActiveItem = (routeName) => location.pathname === `/${routeName}`;

  const handleChooseCompany = (ownerId) => {
    const selectedCompany = role?.find(
      (company) => company.ownerId === ownerId
    );

    if (selectedCompany) {
      setSelectedCompanyId(ownerId);
      setCurrentUser(selectedCompany);
      dispatch(getCurrentUser(selectedCompany));

      dispatch(
        updatefeatures({
          isSubscribed: selectedCompany.isSubscribed,
          withATS: selectedCompany.withATS,
          withAI: selectedCompany.withAI,
          subscription: {
            daysOfValidity: selectedCompany.daysOfValidity,
            endDate: selectedCompany.endDate,
            jobLimit: selectedCompany.jobLimit,
          },
        })
      );

      if (selectedCompany.withATS) {
        navigate("/admin/ats/dashboard");
      } else {
        window.location.reload();
      }
    }
  };

  const handleDropdownToggle = (isOpen) => {
    if (isOpen) {
      getRoleKey();
    }
  };

  useEffect(() => {
    if (errorRole) {
      console.log(errorRole);
      handleError(errorRole, "client", "switchUser");
    }
  }, [errorRole]);

  useEffect(() => {
    if (location?.state?.backCurrentLoginUser) {
      console.log(company, features);
      setSelectedCompanyId(company.ownerId);
      setCurrentUser(company);
      dispatch(getCurrentUser(company));

      dispatch(
        updatefeatures({
          isSubscribed: company.isSubscribed,
          withATS: company.withATS,
          withAI: company.withAI,
          subscription: {
            daysOfValidity: company.daysOfValidity,
            endDate: company.endDate,
            jobLimit: company.jobLimit,
          },
        })
      );
    }
  }, [location?.state?.backCurrentLoginUser]);

  // const hasFetchedRole = localStorage.getItem("hasFetchedRole");
  // useEffect(() => {
  //   if (!hasFetchedRole) {
  //     getRoleKey();
  //     localStorage.setItem("hasFetchedRole", "true");
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log("first");
  //   if (role) {
  //     const selectedCompany = role?.find(
  //       (company) => company.ownerId === clientId
  //     );
  //     console.log("Dispatching getCurrentUser and updatefeatures++++++++++");
  //     if (selectedCompany) {
  //       console.log("Dispatching getCurrentUser and updatefeatures");
  //       setSelectedCompanyId(clientId);
  //       setCurrentUser(selectedCompany); // Store the entire company object
  //       dispatch(getCurrentUser(selectedCompany));

  //       dispatch(
  //         updatefeatures({
  //           isSubscribed: selectedCompany.isSubscribed,
  //           withATS: selectedCompany.withATS,
  //           withAI: selectedCompany.withAI,
  //           subscription: {
  //             daysOfValidity: selectedCompany.daysOfValidity,
  //             endDate: selectedCompany.endDate,
  //             jobLimit: selectedCompany.jobLimit,
  //           },
  //         })
  //       );
  //     }
  //   }
  // }, [role]);

  // console.log(role);

  return (
    <div className="header-bg">
      <Container style={{ padding: "0" }}>
        <Navbar
          expand="lg"
          bg="light"
          style={{ padding: 0, background: "#ffffff" }}
        >
          <NavbarBrand>
            <img
              alt="nxt-logo"
              src={require("../assets/images/nxt-logo.png")}
              className="nxt-logo"
              onClick={handleClickLogo}
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggleOffcanvas} />

          <Collapse isOpen={isOpen} navbar>
            <Nav className="nav-menu" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret id="dropdown-menu">
                  Profile
                </DropdownToggle>
                <DropdownMenu end id="dropdown-option">
                  <DropdownItem onClick={handleCompanyProfile}>
                    Company Profile
                  </DropdownItem>
                  <DropdownItem onClick={handleManageJob}>
                    Manage Job
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink
                  onClick={handleTalentList}
                  className="talent-list-btn"
                  active={isActiveItem("client/talentlist")}
                >
                  Applicant Pool
                </NavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="talent-list-btn">
                  Help
                </DropdownToggle>
                <DropdownMenu end id="dropdown-option">
                  <DropdownItem onClick={() => navigate("/faq")}>
                    FAQs
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/contact-us")}>
                    Contact Us
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/privacy-policy")}>
                    Privacy Policy
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => navigate("/terms-and-condition")}
                  >
                    Terms and Conditions
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/data-deletion")}>
                    Data Deletion
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/cookie-policy")}>
                    Cookie Policy
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Button
              onClick={handleAddpostjobClick}
              className="post-a-job-btn me-2"
              style={{ background: "#efbb20", border: "none" }}
            >
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              Post a Job
            </Button>
            <DropdownButton
              variant="company__list-btn"
              id="dropdown-item-button"
              title={currentUser?.companyName || companyName}
              disabled={isLoading}
              onToggle={handleDropdownToggle}
            >
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <Spinner animation="border" />
                </div>
              ) : (
                role?.map((company) => (
                  <Dropdown.Item
                    as="button"
                    key={company.ownerId}
                    style={{
                      color:
                        currentUser?.companyName === company.companyName ||
                        companyName === company.companyName
                          ? "blue"
                          : "#000000",
                    }}
                    onClick={() => handleChooseCompany(company.ownerId)}
                  >
                    {company.companyName}
                  </Dropdown.Item>
                ))
              )}
            </DropdownButton>
          </Collapse>
        </Navbar>
      </Container>
      {showModal && (
        <CustomModal
          hasOption={false}
          show={showModal}
          buttonName="OK"
          onHide={closeModal}
          modalMessage={modalMessage}
          modalType={messageType}
          onClick={closeModal}
          showLogoutButton={showLogoutButton}
          handleLogout={handleLogout}
        />
      )}
      {showModal && (
        <CustomModal
          hasOption={false}
          show={showModal}
          buttonName="OK"
          onHide={closeModal}
          modalMessage={modalMessage}
          modalType={messageType}
          onClick={closeModal}
          showLogoutButton={showLogoutButton}
          handleLogout={handleLogout}
        />
      )}
    </div>
  );
}

export default Header;
