import React from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import Stepper from "react-stepper-horizontal";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import { Button, Row } from "react-bootstrap";
import SubsSelectContent from "../../../../components/LayoutClient/Subscription/SubsSelect/SubsSelectContent";
import "../../../../../src/assets/scss/Client/Layout/Subscription/Subsciption.scss";
import { useNavigate } from "react-router-dom";

const SubscriptionAddOns = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="admin">
        <div className="sidebar">
          <AdminSidebar />
        </div>
        <div className="navbarContent">
          <Navbar layout="admin" />
          <div className="pageContainer subsciption-container border-none">
            <WhiteContainer>
              <div className="mb-5 stepper">
                <Stepper
                  activeColor="#8046FA"
                  defaultColor="#EFF0F6"
                  completeColor="#8046FA"
                  defaultBarColor="#EFF0F6"
                  steps={[
                    { title: "Select Add On" },
                    { title: "Configure Add On" },
                    { title: "Checkout" },
                  ]}
                  activeStep={0}
                />
              </div>
              <h2 className="text-center pb-5">Select Add On</h2>
              <Row>
                <div className="d-flex justify-content-center">
                  <div>
                    <div className="subsciption-container border-0 shadow-none">
                      <div className="container--subs__card card-shadow bg-white p-4 card">
                        <h2 className="container--subs__card--plan text-center my-2 fw-bold">
                          Multi-user
                        </h2>
                        <div className="container--subs__card--amount d-flex justify-content-center">
                          <h5 className="me-2">₱</h5>
                          <h2 className="display-4"> 2,000</h2>
                        </div>
                        <p className="text-center">per month</p>
                        <Button
                          onClick={() =>
                            navigate(
                              "/admin/ats/subscription/subscription-config"
                            )
                          }
                          className="d-block p-3 btn-green border-0 mb-5"
                        >
                          SUBSCRIBE NOW
                        </Button>
                        <ul className="list-unstyled">
                          <li className="mb-4">
                            <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
                            same access to the client as the subscriber who
                            invited them
                          </li>
                          <li className="mb-4">
                            <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
                            added member will have their own employer profile
                          </li>
                          <li>
                            <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
                            added member can switch access if they have multiple
                            clients
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Button
                      variant="outline-secondary"
                      className="py-3 fw-bold w-100"
                      onClick={() => {
                        navigate("/admin/ats/subscription");
                      }}
                    >
                      BACK
                    </Button>
                  </div>
                </div>
              </Row>
            </WhiteContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionAddOns;
