import React, { useEffect, useState } from "react";
import "../../assets/scss/Jobposting.scss";
import { Container } from "reactstrap";
import SidebarTalent from "../../components/LayoutTalent/SidebarTalent";
import { TabTitle } from "../../Utilities/FunctionTitle";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import BannerJobListDetails from "../../components/LayoutTalent/BannerJobListDetails";
import SidebarJobList from "../../components/LayoutTalent/JobList/SidebarJobList";
import JobListSidebar from "../../components/LayoutTalent/JobList/JobListDetails/JobListSidebar";
import JobListContent from "../../components/LayoutTalent/JobList/JobListDetails/JobListContent";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import { useNavigate, useParams } from "react-router-dom";
import { useTalent } from "../../hook/useTalent";
import { List } from "react-content-loader";
import Footer from "../LandingPage/Components/Footer";
import CustomModal from "../../components/UI/Modal";
import { Message } from "../../Utilities/Reusable/Message/Message";

const JobListDetailsPageTalent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [modalInfo, setModalInfo] = useState({ show: false });
  const getJobPayload = JSON.parse(localStorage.getItem("jobPayload"));
  const {
    viewJobDetails,
    viewJobData,
    viewJobDetailsLoad,
    viewJobErrorStatus,
  } = useTalent();
  const [jobDetails, setJobDetails] = useState([]);
  console.log(jobDetails, viewJobData);
  useEffect(() => {
    viewJobDetails(getJobPayload.id, getJobPayload.job_id);
  }, [id]);

  useEffect(() => {
    if (viewJobData) {
      setJobDetails(viewJobData);
    }
  }, [viewJobData]);

  useEffect(() => {
    if (viewJobErrorStatus === 400) {
      setModalInfo({
        show: true,
        message: Message.talent.viewJob[400],
        modalType: "Error",
        func: "isJobDeleted",
        bName: "Back to applied job",
      });
    }
  }, [viewJobErrorStatus]);

  TabTitle("NXT | Job List Details");

  const handleModalBtn = () => {
    if (modalInfo.func === "isJobDeleted") {
      navigate("/talent/applied-job");
    }
  };

  return (
    <div className="background-imagePost">
      <TalentAnalytics />{" "}
      <div className="header-fixed">
        <HeaderTalent />
      </div>
      {jobDetails.length === 0 ? (
        <div className="loader_viewJob">
          <List />
        </div>
      ) : (
        <>
          <div className="jobposting-banner">
            <BannerJobListDetails jobDetails={jobDetails} />{" "}
          </div>
          <Container className="jobposting-body">
            <div>
              <div className="sidebar-container">
                <JobListSidebar jobDetails={jobDetails} />
              </div>
            </div>

            <div className="company-profile-container">
              <JobListContent jobDetails={jobDetails} />
            </div>
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(viewJobData) }}
            />
          </Container>
        </>
      )}
      <CustomModal
        show={modalInfo.show}
        modalType={modalInfo.modalType}
        modalMessage={modalInfo.message}
        buttonName={modalInfo.bName}
        onClick={() => handleModalBtn()}
      />
      <Footer />
    </div>
  );
};

export default JobListDetailsPageTalent;
