import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import { Button } from "react-bootstrap";
import "../../../../assets/scss/Page/Admin/ATS/UserManagement/_atsUserManagement.scss";
import UserManagementTable from "./UserManagementTable/UserManagementTable";
import UserManagementModal from "./UserManagementTable/UserManagementModal";
import CustomModal from "../../../../components/UI/Modal";
import { useRole } from "../../../../hook/role/useRole";

import { useMessageHandler } from "../../../../hook/error/messageHandler";

const UserManagement = () => {
  const {
    isLoading,
    delUserAPI,
    getAllClientUsers,
    allClientUserList,
    errorRole,
  } = useRole();
  console.log(errorRole);
  const {
    showModal,
    modalMessage,
    messageType,
    showLogoutButton,
    status,
    handleResponse,
    handleError,
    handleLogout,
    closeModal,
  } = useMessageHandler();

  console.log(showModal);

  console.log(showModal);

  const [show, setShow] = useState(false);
  const [isModalOpen, setShowModal] = useState(false);
  const [delBtnClick, setDelBtnClick] = useState(false);
  const [addBtnClick, setAddBtnClick] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [needReload, setNeedReload] = useState(false);
  const handleAddUserModal = () => {
    setShow(true, { state: { addUser: "adduser" } });
  };

  const clickTrue = (modalMode) => {
    console.log("Im clicking");
    if (modalMode === "deleteOption") {
      setDelBtnClick(true);
    } else if (modalMode === "addOption") {
      setAddBtnClick(true);
    }
  };

  const handleAddModal = (isTrue, modalMode) => {
    console.log(isTrue, modalMode);
    setShowModal(isTrue);
    setModalMode(modalMode);
  };

  const [load, setLoad] = useState(false);
  const handleLoadTable = () => {
    setLoad(true);
    setShowModal(false);
    setAddBtnClick(false);
    // setLoad(false);
  };

  const onAddUserSuccess = () => {
    setModalMode("successAdd");
    setShowModal(true);
  };

  const handleErrorAddModal = (errorType) => {
    if (errorType === "alreadyExist") {
      setModalMode("alreadyExist");
      // setShowModal(true);
    } else if (errorType === "ISE") {
      setModalMode("ISE");
    } else if (errorType === "") {
    }
  };

  const handleShowAddUserModal = () => {
    setModalMode("addUserOption");
    setShowModal(true);
  };

  const [clickAddUser, setClickAddUser] = useState(false);
  const [addConfirmation, setAddConfirmation] = useState(false);

  const confirmAddUser = () => {
    setClickAddUser(true);
    setAddConfirmation(true);
    setShowModal(false);
  };

  const [clickDeleteUser, setClickDeleteUser] = useState(false);
  const delUser = () => {
    setDelBtnClick(true);
    setShowModal(false);
    setClickDeleteUser(true);
  };

  const onEditUserSuccess = async () => {
    setModalMode("successEdit");
    setShowModal(true);
    setAddConfirmation(false);
  };

  const handelchangeClickDelUser = (value) => {
    setClickDeleteUser(value);
  };

  const handleChangeAddUser = (value) => {
    setClickAddUser(value);
  };

  useEffect(() => {
    getAllClientUsers();
  }, []);

  const handleGetError = (value) => {
    if (!value) {
      console.log("No error data provided.");
      return;
    }

    console.log("Error received:", value);

    if (value.response && value.response.status) {
      handleError(value, "client", "deleteUser");
    } else {
      console.log("Unexpected error format:", value);
    }
  };

  const handleAddErr = (value) => {
    if (!value) {
      console.log("No error data provided.");
      return;
    }

    console.log("Error received:", value);

    if (value.response && value.response.status) {
      console.log(value);
      handleError(value, "client", "addUser");
    } else {
      console.log("Unexpected error format:", value);
    }
  };

  const handleUserDeleteSuccess = () => {
    setModalMode("successDelete");
    setShowModal(true);
  };

  const handleAddUserSuccess = (value) => {
    if (!value) {
      console.log("No error data provided.");
      return;
    }

    console.log("Error received:", value);
    if (value.response && value.response.status) {
      console.log(value);
      handleResponse(value, "client", "addUser");
    } else {
      console.log("Unexpected error format:", value);
    }
  };

  const loadTable = () => {
    getAllClientUsers();
    setShowModal(false);
  };
  useEffect(() => {
    if (errorRole) {
      console.log(errorRole);
      handleError(errorRole, "client", "getUser");
    }
  }, [errorRole]);

  console.log(
    status,
    errorRole,
    showModal,
    modalMessage,
    messageType,
    showLogoutButton,
    status
  );
  useEffect(() => {
    console.log(status);
    if (status === 400) {
      setNeedReload(true);
    }
  }, [status]);

  console.log(allClientUserList);
  return (
    <div className="admin">
      {/* {modalMode} */}
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer">
          <WhiteContainer>
            <div className="ats__user-management">
              <div className="d-flex p-4">
                {allClientUserList?.roleType !== "recruiter" && (
                  <Button
                    className="btn border-0 ats__btn-adduser ms-auto"
                    onClick={handleAddUserModal}
                  >
                    Add user
                  </Button>
                )}
              </div>

              <UserManagementTable
                setShowModal={setShowModal}
                setModalMode={setModalMode}
                onDeleteSuccess={handleUserDeleteSuccess}
                deleteUser={delBtnClick}
                load={isLoading}
                changeClickDelUser={handelchangeClickDelUser}
                clickDeleteUser={clickDeleteUser}
                allClientUserList={allClientUserList}
                err={handleGetError}
              />
            </div>
          </WhiteContainer>
        </div>
      </div>{" "}
      {showModal && (
        <CustomModal
          hasOption={false}
          show={showModal}
          buttonName={needReload ? "Reload" : "OK"}
          onHide={closeModal}
          modalMessage={modalMessage}
          modalType={
            modalMessage === "You have reached the maximum number of users."
              ? "Unable to Add"
              : messageType
          }
          onClick={needReload ? () => window.location.reload() : closeModal}
          showLogoutButton={showLogoutButton}
          handleLogout={handleLogout}
          hasXmark={
            modalMessage === "You have reached the maximum number of users." &&
            true
          }
        />
      )}
      <UserManagementModal
        show={show}
        close={() => setShow(false)}
        setAddModal={handleAddModal}
        addBtnClick={addBtnClick}
        setModalMode={setModalMode}
        onEditUserSuccess={onEditUserSuccess}
        onAddUserSuccess={onAddUserSuccess}
        showAddModal={handleShowAddUserModal}
        addConfirmation={addConfirmation}
        onErrorAddModal={handleErrorAddModal}
        addErr={handleAddErr}
        showLogoutButton={showLogoutButton}
        changeAddUser={handleChangeAddUser}
        clickAddUser={clickAddUser}
        invitedSuccessfully={handleAddUserSuccess}
      />
      <CustomModal
        hasOption={true}
        show={isModalOpen}
        buttonName={
          modalMode === "successDelete" ||
          modalMode === "successAdd" ||
          modalMode === "alreadyExist" ||
          modalMode === "successEdit"
            ? "OK"
            : "YES"
        }
        onHide={() => setShowModal(false)}
        secondButtonName={
          modalMode === "successDelete" ||
          modalMode === "successAdd" ||
          modalMode === "alreadyExist" ||
          modalMode === "successEdit"
            ? null
            : "NO"
        }
        modalMessage={
          modalMode === "addUserOption"
            ? "Confirm adding this user?"
            : modalMode === "successAdd"
            ? "User successfully invited!"
            : modalMode === "deleteUserOption"
            ? "Are you sure you want to remove this user?"
            : modalMode === "successDelete"
            ? "User Succesfully Removed"
            : modalMode === "alreadyExist"
            ? "Email already registered."
            : modalMode === "successEdit"
            ? "User role change successful"
            : ""
        }
        modalType={
          modalMode === "successAdd" ||
          modalMode === "successDelete" ||
          modalMode === "successEdit"
            ? "Success"
            : modalMode === "addUserOption" || modalMode === "deleteUserOption"
            ? "Warning"
            : "Oops!"
        }
        onClick={() => {
          modalMode === "addUserOption"
            ? confirmAddUser()
            : modalMode === "deleteUserOption"
            ? delUser()
            : modalMode === "alreadyExist"
            ? setShowModal(false)
            : modalMode === "successEdit" ||
              modalMode === "successDelete" ||
              modalMode === "successAdd"
            ? loadTable()
            : setShowModal(false);
        }}
      />
    </div>
  );
};

export default UserManagement;
