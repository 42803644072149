import { useDispatch, useSelector } from "react-redux";
import { CustomNavigate } from "../../../../../Utilities/Navigation/CustomNavigation";
import { logout } from "../../../../../services/Actions/userSlice";

import "../../../../../assets/scss/Page/Outer/Login/_login.scss";
import Google from "../../../../../layouts/LoginRegSSOIcon/SocialMediaButtons/Google";
import CustomButton from "../../../../../components/UI/CustomButton";
import Facebook from "../../../../../layouts/LoginRegSSOIcon/SocialMediaButtons/Facebook";
import FacebookLogin from "../../../../../layouts/LoginRegSSOIcon/SocialMediaButtons/FacebookLogin";
import GoogleLogin from "../../../../../layouts/LoginRegSSOIcon/SocialMediaButtons/GoogleLogin";
import CustomModal from "../../../../../components/UI/Modal";
import { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SubscriptionFunction } from "../../../../../Utilities/Reusable/Function/Subscription/Subscription";

const LoginOption = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const dispatch = useDispatch();
  const { goToRegistrationClient } = CustomNavigate();
  const { goToRegistrationTalent } = CustomNavigate();
  const userType = useSelector(
    (state) => state.reducer.user.currentUser?.userType
  );

  const location = useLocation();
  const sendState = location.state?.sendClientState;
  console.log("State create account", sendState);

  const click = () => {
    dispatch(logout());
  };

  const handleModalErrorClose = () => {
    setModalErrorShow(false);
  };

  const { getURLSubsPlanPrice, feature_pricing } = SubscriptionFunction();

  console.log(feature_pricing);
  useEffect(() => {
    getURLSubsPlanPrice();
  }, []);

  const handleModalClose = async () => {
    await setModalShow(false);

    if (userType === "client") {
      console.log("company-profile");
      navigate("/client/company-profile");
    } else if (feature_pricing) {
      console.log("company-profile");
      navigate("/client/company-profile/?feature=pricing");
    } else if (userType === "applicant") {
      navigate("/talent/profile");
    }
  };

  // Function to handle error message from GoogleLogin
  const handleSSOError = (error) => {
    setErrorMsg(error); // Set the error message
    setModalErrorShow(true); // Show the modal
  };

  const handleSSOSuccess = (msg) => {
    setSuccessMessage(msg); // Set the error message
    setModalShow(true); // Show the modal
  };

  return (
    <div className="loginOption">
      <div className="separate">
        <hr />
        <p>or</p>
        <hr />
      </div>
      <div className="socialMediaIcons">
        <GoogleLogin onError={handleSSOError} onSuccess={handleSSOSuccess} />
        <div>
          <FacebookLogin
            onError={handleSSOError}
            onSuccess={handleSSOSuccess}
          />
        </div>
      </div>

      <div className="buttonSignupContainer">
        <h4 onClick={click}>Don't have an account yet?</h4>
        {sendState === "client" ? (
          <CustomButton
            name="Sign Up"
            className="signupBtn"
            onClick={() => goToRegistrationClient(feature_pricing)}
          />
        ) : (
          <CustomButton
            name="Sign Up"
            className="signupBtn"
            onClick={goToRegistrationTalent}
          />
        )}

        <Link to="/">Back to Homepage</Link>
      </div>
      <CustomModal
        show={modalErrorShow} // Show the modal when needed
        color="red" // Color for the icon
        icon={faTimes} // Icon for error
        modalMessage={errorMessage} // Error message
        modalType="Warning" // Type of modal (you can customize this)
        buttonName="Close" // Button label
        onClick={() => handleModalErrorClose()}
      />
      <CustomModal
        show={modalShow} // Show the modal when needed
        color="green" // Color for the icon
        icon={faTimes} // Icon for error
        modalMessage={successMessage} // Error message
        modalType="Success" // Type of modal (you can customize this)
        buttonName="Close" // Button label
        onClick={() => {
          handleModalClose();
        }}
      />
    </div>
  );
};

export default LoginOption;
