import React from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import Navbar from "../../../../layouts/Navbar";
import Stepper from "react-stepper-horizontal";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SubscriptionConfig = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    window.history.back(); // Navigates back in the browser's history
  };

  return (
    <>
      <div className="admin">
        <div className="sidebar">
          <AdminSidebar />
        </div>
        <div className="navbarContent">
          <Navbar layout="admin" />
          <div className="pageContainer subsciption-container border-0 shadow-none">
            <WhiteContainer>
              <div className="mb-5 stepper">
                <Stepper
                  activeColor="#8046FA"
                  defaultColor="#EFF0F6"
                  completeColor="#8046FA"
                  defaultBarColor="#EFF0F6"
                  steps={[
                    { title: "Select Add On" },
                    { title: "Configure Add On" },
                    { title: "Checkout" },
                  ]}
                  activeStep={1}
                />
              </div>
              <h2 className="text-center pb-5">Configure Add On</h2>
              <Row>
                <Col md={8}>
                  <div className="bg-white config-subs p-4">
                    <h2 className="my-2 fw-bold">Multi-user</h2>
                    <div className="d-flex mx-auto config-subs--amount">
                      <h2>
                        <span className="config-subs--peso">₱</span>{" "}
                        <span className="config-subs--amount__num">2,000</span>
                        <span className="config-subs--month">
                          {" "}
                          / month
                        </span>{" "}
                      </h2>
                    </div>
                    <div className="mt-3 mb-3">
                      <div className="subs-features">Add on Features:</div>
                      {/* {selectedPlan.description.map((item, index) =>
                        item.isBullet ? (
                          <ul key={index}>
                            <li>{item.text}</li>
                          </ul>
                        ) : (
                          <label className="pt-2 d-flex" key={index}>
                            {item.text}
                          </label>
                        )
                      )} */}
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
                          same access to the client as the subscriber who
                          invited them
                        </li>
                        <li className="mb-2">
                          <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
                          added member will have their own employer profile
                        </li>
                        <li>
                          <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
                          added member can switch access if they have multiple
                          clients
                        </li>
                      </ul>
                    </div>

                    <Form>
                      <Form.Label htmlFor="inputPassword5">
                        <small>Select Billing Cycle:</small>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="mb-4"
                        // onChange={(e) => setBilling(e.target.value)}
                      >
                        {/* <option value="3">
                  ₱{" "}
                  {subsData[0].activeSubscription.current !== "freemium"
                    ? proratedPrice?.toLocaleString()
                    : selectedPlan.amount.toLocaleString()}{" "}
                  / {selectedPlan.month} Month
                </option> */}
                        <option value={1}>₱2,000 / 1 Month</option>
                        {/* <option value={3}>₱6000 / 3 Months</option>
                        <option value={6}>₱12000 / 6 Months</option>
                        <option value={9}>₱18000 / 9 Months</option>
                        <option value={12}>₱24000 / 12 Months</option> */}
                      </Form.Select>
                    </Form>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="order-summary bg-white p-4">
                    <h2 className="fw-bold order-summary--title">
                      Order Summary
                    </h2>
                    <div className="p-2 receipt__background">
                      <div className="d-flex">
                        <div className="p-2 receipt--plan__name">
                          Multi-user
                        </div>
                        <div className="p-2 ms-auto">₱2,000</div>
                      </div>
                      <div className="d-flex border-bottom">
                        <div className="p-2 ms-auto">1 x month</div>
                      </div>
                      <div className="d-flex">
                        <div className="p-2 ms-auto total">
                          Total ₱2,000{" "}
                          {/* {subsData[0].activeSubscription.current !== "freemium"
                            ? (proratedPrice * billing).toLocaleString()
                            : (selectedPlan.amount * billing).toLocaleString()} */}
                        </div>
                      </div>
                    </div>
                    <div className="text-center receipt--total__amount mt-3">
                      <h2>
                        ₱2,000{" "}
                        {/* {subsData[0].activeSubscription.current !== "freemium"
                          ? (proratedPrice * billing).toLocaleString()
                          : (selectedPlan.amount * billing).toLocaleString()} */}
                      </h2>
                    </div>
                    <div className="text-center mb-4 receipt--total--text">
                      <small>Total</small>
                    </div>
                    <div className="d-grid gap-2">
                      <Button
                        variant=""
                        className="btn-green py-3"
                        onClick={() =>
                          navigate(
                            "/admin/ats/subscription/subscription-checkout"
                          )
                        }
                      >
                        Continue
                      </Button>
                      <Button
                        variant="outline-secondary"
                        className="py-3 fw-bold"
                        onClick={handleGoBack}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </WhiteContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionConfig;
