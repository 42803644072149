import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import "../../../../assets/scss/Page/Admin/ATS/Dashboard/_dashboard.scss";
import {
  faBuilding,
  faDollarSign,
  faEllipsisVertical,
  faExclamation,
  faFile,
  faInfo,
  faPerson,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GraphJobPosted from "./DashboardGraph/GraphJobPosted";
import GraphTalentApplied from "./DashboardGraph/GraphTalentApplied";
import GraphCompanyList from "./DashboardGraph/GraphCompanyList";
import { useAuthToken, useRoleKey } from "../../../../Utilities/Auth/Token";
import axios from "axios";
import Config from "../../../../Config/config";
import JobPosting from "./DashboardGraph/JobPosting";
import GraphDraftJobs from "./DashboardGraph/GraphDraftJobs";
import GraphPoolingList from "./DashboardGraph/GraphPoolingList";
import GraphHiredList from "./DashboardGraph/GraphHiredList";
import { handleAxiosError } from "../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
import { useMessageHandler } from "../../../../hook/error/messageHandler";
import CustomModal from "../../../../components/UI/Modal";

const AtsDashboard = () => {
  const {
    showModal,
    modalMessage,
    messageType,
    showLogoutButton,
    status,
    handleResponse,
    handleError,
    handleLogout,
    closeModal,
  } = useMessageHandler();
  const [graphData, setGraphData] = useState(null);
  const token = useAuthToken();
  const code = useRoleKey();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  useEffect(() => {
    graphScore();
  }, []);

  const graphScore = async () => {
    try {
      const response = await axios.get(
        `${Config.ats}/api/ats/manage-job/dashboard`,
        {
          headers,
        }
      );
      setGraphData(response.data.result);
    } catch (error) {
      if (error.response.status >= 403) {
        handleAxiosError(error);
      } else {
        handleError(error, "client", "dashboard");
      }

      // handleAxiosError(error);
      console.log(error);
    }
  };
  for (const key in graphData) {
    if (!Object.keys(graphData[key]).includes("sortedArr")) break;

    if (graphData[key].sortedArr.length === 1) {
      const valueName = Object.keys(graphData[key].sortedArr[0]).find(
        (attr) => attr !== "name"
      );
      const unshiftValue = {};
      unshiftValue[valueName] = 0;
      unshiftValue["name"] = "start";
      graphData[key].sortedArr.unshift(unshiftValue);
    }
  }

  const tipJobPosted = (
    <Tooltip id="tooltip">
      Monitor the total number of job posts you've released in NXT.
    </Tooltip>
  );

  const tipActiveJob = (
    <Tooltip id="tooltip">
      Monitor the number of active job posts and ensure your opportunities
      remain visible to potential candidates.
    </Tooltip>
  );

  const tipClosedJob = (
    <Tooltip id="tooltip">
      Keep track of expired or closed job listings to analyze your recruitment
      efforts and make informed decisions for future hires.
    </Tooltip>
  );
  const tipDraftJob = (
    <Tooltip id="tooltip">
      Save drafts of your job listings and polish them before publishing to
      ensure maximum impact and effectiveness.
    </Tooltip>
  );
  const tipAppPool = (
    <Tooltip id="tooltip">
      Qualified applicants who have expressed interest in a job posting.
    </Tooltip>
  );
  const TipHiredList = (
    <Tooltip id="tooltip">
      Your top candidates who were selected and hired for open positions.
    </Tooltip>
  );

  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer">
          <div className="ats__dashboard p-4">
            <Row>
              <Col sm={6} md={6} lg={3}>
                <div className="ats__dashboard-card p-3 mb-3">
                  <div class="ats__dashboard-circle-jobPosted">
                    <FontAwesomeIcon
                      icon={faFile}
                      className="ats__dashboard-icon d-flex mx-auto pt-3"
                    />
                  </div>
                  <p className="mt-4 mb-0">
                    <strong>{graphData?.totalJobs?.count}</strong>
                  </p>
                  <p className="mt-2 d-flex">
                    Total Job Posted
                    <OverlayTrigger placement="right" overlay={tipJobPosted}>
                      <div className="tooltip-dashboard ms-2 px-2 position-relative">
                        <FontAwesomeIcon
                          icon={faInfo}
                          className="position-absolute top-50 start-50 translate-middle"
                        />
                      </div>
                    </OverlayTrigger>
                  </p>
                  <GraphJobPosted graphData={graphData} />
                  <div
                    className={`${
                      graphData?.totalJobs?.count === 0 ? "overlay" : "d-none"
                    }`}
                  >
                    No data found
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <div className="ats__dashboard-card p-3 mb-3">
                  <div class="ats__dashboard-circle-talentApplied">
                    <FontAwesomeIcon
                      icon={faFile}
                      className="ats__dashboard-icon d-flex mx-auto pt-3"
                    />
                  </div>
                  <p className="mt-4 mb-0">
                    <strong>{graphData?.openJobs?.count}</strong>
                  </p>
                  <p className="mt-2 d-flex">
                    Total Active Jobs
                    <OverlayTrigger placement="right" overlay={tipActiveJob}>
                      <div className="tooltip-dashboard ms-2 px-2 position-relative">
                        <FontAwesomeIcon
                          icon={faInfo}
                          className="position-absolute top-50 start-50 translate-middle"
                        />
                      </div>
                    </OverlayTrigger>
                  </p>
                  <GraphTalentApplied graphData={graphData} />
                  <div
                    className={`${
                      graphData?.openJobs?.count === 0 ? "overlay" : "d-none"
                    }`}
                  >
                    No data found
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <div className="ats__dashboard-card p-3 mb-3">
                  <div class="ats__dashboard-circle-company">
                    <FontAwesomeIcon
                      icon={faFile}
                      className="ats__dashboard-icon d-flex mx-auto pt-3"
                    />
                  </div>
                  <p className="mt-4 mb-0">
                    <strong>{graphData?.closedJobs?.count}</strong>
                  </p>
                  <p className="mt-2 d-flex">
                    Total Expired/Close Jobs
                    <OverlayTrigger placement="right" overlay={tipClosedJob}>
                      <div className="tooltip-dashboard ms-2 px-2 position-relative">
                        <FontAwesomeIcon
                          icon={faInfo}
                          className="position-absolute top-50 start-50 translate-middle"
                        />
                      </div>
                    </OverlayTrigger>
                  </p>
                  <GraphCompanyList graphData={graphData} />
                  <div
                    className={`${
                      graphData?.closedJobs?.count === 0 ? "overlay" : "d-none"
                    }`}
                  >
                    No data found
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <div className="ats__dashboard-card p-3 mb-3">
                  <div class="ats__dashboard-circle-draft">
                    <FontAwesomeIcon
                      icon={faFile}
                      className="ats__dashboard-icon d-flex mx-auto pt-3"
                    />
                  </div>
                  <p className="mt-4 mb-0">
                    <strong>{graphData?.draftJobs?.count}</strong>
                  </p>
                  <p className="mt-2 d-flex">
                    Total Draft Jobs
                    <OverlayTrigger placement="right" overlay={tipDraftJob}>
                      <div className="tooltip-dashboard ms-2 px-2 position-relative">
                        <FontAwesomeIcon
                          icon={faInfo}
                          className="position-absolute top-50 start-50 translate-middle"
                        />
                      </div>
                    </OverlayTrigger>
                  </p>
                  <GraphDraftJobs graphData={graphData} />
                  <div
                    className={`${
                      graphData?.draftJobs?.count === 0 ? "overlay" : "d-none"
                    }`}
                  >
                    No data found
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <div className="ats__dashboard-card p-3 mb-3">
                  <div class="ats__dashboard-circle-pooling">
                    <FontAwesomeIcon
                      icon={faUserGroup}
                      className="ats__dashboard-icon d-flex mx-auto pt-3"
                    />
                  </div>
                  <p className="mt-4 mb-0">
                    <strong>{graphData?.poolingList?.count}</strong>
                  </p>
                  <p className="mt-2 d-flex">
                    Applicant Pool
                    <OverlayTrigger placement="right" overlay={tipAppPool}>
                      <div className="tooltip-dashboard ms-2 px-2 position-relative">
                        <FontAwesomeIcon
                          icon={faInfo}
                          className="position-absolute top-50 start-50 translate-middle"
                        />
                      </div>
                    </OverlayTrigger>
                  </p>
                  <GraphPoolingList graphData={graphData} />
                  <div
                    className={`${
                      graphData?.poolingList?.count === 0 ? "overlay" : "d-none"
                    }`}
                  >
                    No data found
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <div className="ats__dashboard-card p-3 mb-3">
                  <div class="ats__dashboard-circle-hired">
                    <FontAwesomeIcon
                      icon={faUserGroup}
                      className="ats__dashboard-icon d-flex mx-auto pt-3"
                    />
                  </div>
                  <p className="mt-4 mb-0">
                    <strong>{graphData?.hiredList?.count}</strong>
                  </p>
                  <p className="mt-2 d-flex">
                    Active Talent
                    <OverlayTrigger placement="right" overlay={TipHiredList}>
                      <div className="tooltip-dashboard ms-2 px-2 position-relative">
                        <FontAwesomeIcon
                          icon={faInfo}
                          className="position-absolute top-50 start-50 translate-middle"
                        />
                      </div>
                    </OverlayTrigger>
                  </p>
                  <GraphHiredList graphData={graphData} />
                  <div
                    className={`${
                      graphData?.hiredList?.count === 0 ? "overlay" : "d-none"
                    }`}
                  >
                    No data found
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} lg={6}>
                <div className="ats__dashboard-card p-5 position-relative ats__dashboard-modnumber">
                  <JobPosting graphData={graphData} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>{" "}
      {showModal && (
        <CustomModal
          hasOption={false}
          show={showModal}
          buttonName={"Logout"}
          onHide={closeModal}
          modalMessage={modalMessage}
          modalType={messageType}
          onClick={closeModal}
          showLogoutButton={showLogoutButton}
          handleLogout={handleLogout}
        />
      )}
    </div>
  );
};

export default AtsDashboard;
