import React, { useEffect, useState } from "react";
import Config from "../../../../../Config/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import survey from "../../../../../Data/dropdown/survey.json";
import socialMedia from "../../../../../Data/dropdown/socialMedia.json";
import influencer from "../../../../../Data/dropdown/influencer.json";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { loginSuccess } from "../../../../../services/Actions/userSlice";
import CustomMessage from "../../../../../components/Form/ErrorMessage";
import CustomInput from "../../../../../components/Form/CustomInput";
import CustomSelect from "../../../../../components/Form/CustomSelect";
import CustomModal from "../../../../../components/UI/Modal";
import CustomButton from "../../../../../components/UI/CustomButton";
import { checkIsReferralCodeValid } from "../../../../../Utilities/Fetch/ReferralCode";
import { RequestMethod } from "../../../../../Utilities/Reusable/Method/RequestMethod";

const ReferralInformation = ({
  userType,
  ref_id,
  id,
  onSubmit,
  reg_type,
  step,
  backStep,
  onError,
  signUpData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(""); // State to store the selected option
  const [responseMsg, setResponseMsg] = useState("");
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const [errorMsg, setErrorMsg] = useState("");

  const handleSignUp = async (data) => {
    setBtnIsLoading(true);
    switch (data.survey) {
      case "Others (Please specify.)":
        data.survey = data.others;
        break;
      case "Social Media":
        data.survey = data.socialMedia;
        break;
      case "Content Creator / Influencer":
        data.survey = data.influencer;
        break;
      default:
        break;
    }

    if (
      data.survey === "Others (Please specify.)" ||
      data.survey === "Social Media" ||
      data.survey === "Content Creator / Influencer"
    ) {
      setErrorMsg("Please select option");
      return;
    }

    if (data.referralCode.trim() !== "") {
      data.referralCode = data.referralCode.trim();
      try {
        let referralResponse = await checkIsReferralCodeValid(
          data.referralCode
        );
      } catch (error) {
        console.error("Referral code validation error:", error);
        setBtnIsLoading(false);
        return;
      }
    } else {
      delete data.referralCode;
    }

    const { socialMedia, others, influencer, ...requestData } = data;

    try {
      const requestBody = {
        ...requestData,
        userType,
      };

      if (reg_type === "google") {
        requestBody.ref_id = id;
      } else if (reg_type === "fb") {
        requestBody.ref_id = ref_id;
      } else if (reg_type === "email") {
        requestBody.ref_id = ref_id;
      }

      const response = await fetch(`${Config.nxtApi}/api/user/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseBody = await response.text(); // Read the response body as text

      const res = responseBody.split("|");
      if (res.length > 1) {
        setBtnIsLoading(false);
        const signUpInfo = JSON.parse(res[1]).data;
        const isResError = Object.keys(signUpInfo).includes("err");

        if (!isResError) {
          signUpData(signUpInfo);
          onSubmit();
        } else {
          setResponseMsg(signUpInfo.err);
          if (signUpInfo.err === "User already exists.") {
            onError(signUpInfo.err);
          }
        }
      }

      setBtnIsLoading(false);
    } catch (error) {
      setBtnIsLoading(false);
      console.log(error);
      setResponseMsg(error.message); // Use error.message to get the error message
      if (error.message === "User already exists.") {
        onError(error.message);
      }
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleModalClose = () => {
    setModalShow(false);
    if (userType === "applicant") {
      navigate("/talent/profile"); // Redirect to the talent profile
    } else if (userType === "client") {
      navigate("/client/company-profile"); // Redirect to the client company profile
    }
  };

  const handleBackBtn = () => {
    backStep = 4;
    onSubmit(userType, backStep);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleSignUp)}>
        <div className="loginTitleLeft">
          <h1>Referral Information</h1>
        </div>
        <CustomSelect
          label="How did you learn about NXT?"
          data={survey}
          errors={errors}
          {...register("survey", { required: "Survey is required" })}
          defaultOption="Select to choose"
          value={selectedOption}
          onChange={handleSelectChange}
          className="formInputGroup"
        />
        {selectedOption === "Social Media" && (
          <>
            <CustomSelect
              label="Social Media"
              data={socialMedia}
              errors={errors}
              {...register("socialMedia", {
                required: "Social Media is required",
              })}
              defaultOption="Select to choose"
              className="formInputGroup"
            />
          </>
        )}
        {selectedOption === "Content Creator / Influencer" && (
          <>
            <CustomSelect
              label="Content Creator / Influencer"
              data={influencer}
              errors={errors}
              {...register("influencer", {
                required: "Content Creator / Influencer is required",
              })}
              defaultOption="Select to choose"
              className="formInputGroup"
            />
          </>
        )}
        {selectedOption === "Others (Please specify.)" && (
          <>
            <CustomInput
              label="Other (please specify)"
              errors={errors}
              type="text"
              {...register("others", { required: "Others is required" })}
              className="formInputGroup"
            />
          </>
        )}
        {errorMsg && <CustomMessage label={errorMsg} />}
        <CustomInput
          label="Referral Code"
          placeholder="Referral Code"
          errors={errors}
          type="text"
          defaultValue={localStorage.getItem("referralCode") || ""}
          {...register("referralCode")}
          className="formInputGroup"
          labelOptional="(Optional)"
        />
        {responseMsg && <CustomMessage label={responseMsg} />}
        <div className="backNext">
          <CustomButton
            type="button"
            name="Back"
            className="customLRButton"
            onClick={handleBackBtn}
          />{" "}
          <CustomButton
            type="submit"
            name="Submit"
            isLoading={btnIsLoading}
            className="customLRButton"
          />
        </div>
      </form>
      <CustomModal
        buttonName="Close"
        show={modalShow}
        onHide={() => handleModalClose()}
        modalMessage="You are successfully registered"
        modalType="Success"
      />
    </div>
  );
};

export default ReferralInformation;
